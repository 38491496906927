import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getCart } from '../actions/PackActions'
import Packs from './Packs'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  searching: state.pack.searching,
})

const mapDispatchToProps = dispatch => ({
  getCartAction: (kod) => dispatch(getCart(kod)),
})

class ScanCartComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      kod: '',
      listMode: false
    }
  }

  componentDidMount() {
    document.getElementById('kod').focus()
  }

  toggleMode = () => {
    const { listMode } = this.state
    this.setState({
      listMode: !listMode
    })
  }

  handleChange = (event) => {
    const {target} = event
    this.setState({
      [target.name]: target.value
    })
  }

  handleKey = (event) => {
    if (event.which === 13) {
      this.handleSubmit(event)
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { getCartAction } = this.props
    const kod = document.getElementById('kod').value
    if (kod){
      getCartAction(kod)
    } else {
      Popup.alert(intl.get('Wprowadź kod wózka'))
    }
  }

  render() {
    const { searching } = this.props
    const { kod, listMode } = this.state
    if (listMode){
      return (
        <>
        <Packs/>
        <div className="buttons_wrap">
          <button className="pull-left secondary" onClick={this.toggleMode}>{intl.get('Anuluj')}</button>
        </div>
        </>
      )
    } else {
      return (
        <div className="container">
          <form onSubmit={this.handleSubmit}>
            <div className="ramka center">
              <h4>{intl.get('Skanowanie wózka')}</h4>
              <input
                type="text"
                value={kod}
                placeholder={intl.get('Kod wózka')}
                onChange={this.handleChange}
                onKeyPress={this.handleKey}
                className="searchBox"
                id="kod"
                name="kod"
                autoComplete="off"
                />
              <div className="buttons_wrap">
                <button className="pull-left" onClick={this.toggleMode}>{intl.get('Pokaż listę paczek')}</button>
                <input type="submit" className="pull-right" value={intl.get('Dalej')} disabled={searching} />
              </div>
            </div>
          </form>
        </div>
      )
    }
  }
}

const ScanCart = connect(mapStateToProps, mapDispatchToProps)(ScanCartComponent)
export default ScanCart
