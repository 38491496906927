import React from 'react'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import ShippingOrder from './ShippingOrder'

class ShippingList extends React.Component {

    getShippingRow = (shipping) => {
      return (
        <tr data-id={shipping.id}
            key={shipping.id}
            onClick={this.select}>
          <td>{shipping.date}</td>
          <td>{shipping.carrier}</td>
          <td>{shipping.receiver_name}</td>
        </tr>
      )
    }

    select = (e) => {
      const { parent } = this.props
      const value = e.currentTarget.dataset.id
      Popup.close()
      Popup.plugins().shippingOrder(value, parent)
    }

    render() {
      const { shipping_order_ids } = this.props
      return (
        <div className='table-wrapper'>
          <table className='table shipping_list'>
            <thead>
              <tr>
                <th>{ intl.get('Data listu przewozowego') }</th>
                <th>{ intl.get('Metoda dostawy') }</th>
                <th>{ intl.get('Odbiorca') }</th>
              </tr>
            </thead>
            <tbody>
              {shipping_order_ids.map(shipping => this.getShippingRow(shipping))}
            </tbody>
          </table>
        </div>
      )
    }
}

Popup.registerPlugin('shipping', function (shipping_order_ids, new_action, parent) {

    this.create({
        title: intl.get('Przesyłki'),
        content: <ShippingList shipping_order_ids={shipping_order_ids} parent={parent}/>,
        buttons: {
          right: [
            {
              text: intl.get('Dodaj przesyłkę'),
              action: function () {
                new_action()
                Popup.close()
                Popup.plugins().shippingOrder(0, parent)
              }
            },
            {
              text: intl.get('Anuluj'),
              className: 'cancel-button',
              action: function () {
                Popup.close()
              }
            }
          ]
        }
    })
})
