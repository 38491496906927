import Popup from 'react-popup'
import { GET_PACKS, GET_SUGGESTIONS, GET_ORDER, RELEASE_ORDER, SELECT_SHIPPING, SAVE_SHIPPING, GET_BOXES, SET_SEARCHING, SET_PROCESSING, SET_LAST_SCANNED, SET_FILTERS, API_ERROR } from '../constants/ActionTypes'

const initialState = {
  packs: [],
  boxes: false,
  pack: false,
  suggestions: false,
  order: false,
  searching: false,
  processing: false,
  last_scanned: false,
  filters: {
    pak: true,
    multi: true
  }
}

export default function pack(state = initialState, action) {
  switch (action.type) {
    case GET_PACKS:
      return { ...state,
        packs: action.payload
      }
    case GET_SUGGESTIONS:
      return { ...state,
        suggestions: action.payload
      }
    case GET_ORDER:
      return { ...state,
        order: action.payload
      }
    case RELEASE_ORDER:
      return { ...state }
    case GET_BOXES:
      return { ...state,
        boxes: action.payload
      }
    case SAVE_SHIPPING:
      if (action.payload === true){
        return { ...state }
      }
      return { ...state,
        order: {
          ...state.order,
          shipping_order_ids: [
            ...state.order.shipping_order_ids,
            action.payload
          ]
        }
      }
    case SET_SEARCHING:
      return { ...state,
        searching: action.payload
      }
    case SET_PROCESSING:
      return { ...state,
        processing: action.payload
      }
    case SET_FILTERS:
      return { ...state,
        filters: action.payload
      }
    case SET_LAST_SCANNED:
      return { ...state,
        last_scanned: action.payload
      }
    case API_ERROR:
      Popup.alert(action.message)
      return { ...state }
    default:
      return state
  }
}
